.container{
  width: 100%;
  max-width: 940px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.table-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-container{
  width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wf-force-outline-none[tabindex="-1"]:focus {
  outline: none;
}

ul.torolist {
  list-style: none;
  /* Remove default bullets */
  padding-left: 2.2em;
}

ul.torolist li::before {
  content: "\25FC";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00ADD7;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
  position: relative;
  left: -1em;
  font-size: 1.2em;
  top: 2px;
}

.form-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

form input {
  background: #fff;
  border: none;
  border-bottom: 2px solid #D1D5D7;
  outline: none;
  height: 40px;
  box-sizing: border-box;
  padding: 8px 16px;
  margin-bottom: 12px;
  font-size: 1.125rem;
  font-weight: 400;
  width: 100%;
}

form input:focus {
  color: #222;
  background-color: #EDEBFC;
  border-bottom: 2px solid #1bcc88;
}

form [data-field]:focus {
  outline: none;
}

form label {
  color: #ffffff;
  cursor: text;
  line-height: 18px;
  font-size: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
  margin-top: 12px
}

form[toro-form] [data-field-errors] span {
  color: #F44336;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  left: 0;
  bottom: -8px;
  white-space: nowrap;
}

form[toro-form] [data-field-errors] span+ {
  color: #F44336;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  left: 0;
  bottom: -8px;
  white-space: nowrap;
}

form button[type="submit"] {
  position: relative;
  height: 49px;
  margin-top: 24px;
  background-color: #1bcc88;
  color: #fff;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 8px 16px;
  width: 100%;
}

form button[type="submit"]:focus {
  outline: none;
}

form button[type="submit"]:hover {
  background: #1bcc88;
  opacity: 0.8;
}

.form-title {
  color: #091827;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 40px;
  line-height: 25px;
}

.login-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
  color: #8d8d8d;
  font-size: 16px;
}

.login-container a {
  margin-left: 4px;
  color: #00ADD2;
  text-decoration-color: #00ADD2;
  font-weight: bold
}

@keyframes rotate {
  to {
    transform: rotate(360deg)
  }
}

.button-toggled:after {
  content: '';
  animation: rotate 2s infinite linear;
  height: 20px;
  right: 40px;
  top: calc(50% - 13px);
  position: absolute;
  width: 20px;
  background-color: transparent;
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  border-top-color: transparent;
}

.button-toggled {
  background-color: #0093bf70;
}

.form-title {
  margin-top: 0;
}

iframe#_hjRemoteVarsFrame {
  display: none !important;
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

.olark-key, #hbl_code, #olark-data {
  display: none !important;
}

@media print {
  #habla_beta_container_do_not_rely_on_div_classes_or_names {
    display: none !important
  }
}

.w-slider-dot {
  border: 1px solid #49535C;
}

.w-slider-dot.w-active {
  background: #1bcc88;
  border-color: #1bcc88;
}

#olark-wrapper #olark-container path.olark-primary {
  fill: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-branding-link svg path {
  fill: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-theme-bg {
  background-color: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-theme-text {
  color: #ffffff !important;
}

#olark-wrapper .olark-launch-button {
  background-color: #2F6BC6 !important;
}

#olark-wrapper .olark-launch-button svg path {
  stroke: #ffffff !important;
}

#olark-wrapper .olark-launch-button svg circle {
  fill: #ffffff !important;
}

#olark-wrapper .olark-launch-button .olark-close-icon path {
  stroke: #ffffff !important;
}

#olark-wrapper .olark-launch-button .olark-envelope-icon rect {
  stroke: #ffffff !important;
  fill: none !important;
}

#olark-wrapper .olark-launch-button .olark-envelope-icon path {
  stroke: #ffffff !important;
  fill: none !important;
}

#olark-wrapper .olark-launch-button .olark-button-text {
  color: #ffffff !important;
}

#olark-wrapper .olark-top-bar {
  background-color: #2F6BC6 !important;
  color: #ffffff !important;
  border-color: #2a5fb2 !important;
}

#olark-wrapper .olark-top-bar p {
  color: #ffffff !important;
}

#olark-wrapper .olark-top-bar-text {
  color: #ffffff !important;
}

#olark-wrapper .olark-top-bar-arrow {
  fill: #ffffff !important;
}

#olark-wrapper .olark-end-chat-button {
  color: #ffffff !important;
  background-color: rgba(37, 84, 157, 0.5) !important;
}

#olark-wrapper .olark-end-chat-button:hover {
  background-color: #25549d !important;
}

#olark-wrapper #olark-container .olark-operator-message:not(.olark-message-trans-bg) {
  background-color: #2F6BC6 !important;
  color: #ffffff !important;
}

#olark-wrapper #olark-container .olark-is-typing-dot {
  fill: #ffffff !important;
}

#olark-wrapper #olark-container .olark-send-transcript-container .olark-send-transcript-form.olark-inline-form-valid .olark-form-input-container {
  border-color: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-send-transcript-container .olark-send-transcript-form.olark-inline-form-valid .olark-send-icon {
  fill: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-survey-form .olark-survey-form-radio-button:checked {
  background: #2F6BC6 !important;
  border-color: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-agent-status {
  color: #ffffff !important;
}

#olark-wrapper #olark-container .olark-context-icon path {
  stroke: #ffffff !important;
}

#olark-wrapper #olark-container .olark-offline-survey .olark-thank-you-component {
  background-color: #2F6BC6 !important;
  color: #ffffff !important;
}

#olark-wrapper #olark-container .olark-top-bar a {
  color: #ffffff !important;
}

#olark-wrapper #olark-container .olark-form-container .olark-form-send-button:focus {
  border: 2px solid #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-feedback-form-button:focus {
  border: 2px solid #2F6BC6 !important;
  background-color: #32b35e !important;
}

#olark-wrapper #olark-container .olark-restart-button:focus {
  border: 2px solid #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-button:focus {
  border: 2px solid #2F6BC6 !important;
  background-color: #61D286 !important;
  color: #FFFFFF !important;
}

#olark-wrapper #olark-container .olark-message-link-title {
  line-height: normal !important;
}

#olark-wrapper #olark-container .olark-message-link-location {
  line-height: normal !important;
}

#olark-wrapper #olark-container .olark-operator-message a {
  color: #ffffff !important;
}

#olark-wrapper #olark-container .olark-operator-message a svg path {
  fill: #ffffff !important;
}

#olark-wrapper #olark-container .olark-options-message button:focus {
  border: 2px solid #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-options-message button:hover {
  border: 2px solid #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-footer-form-container .olark-chat-send-button:focus {
  border: 2px solid #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-visitor-message:not(.olark-message-has-border) {
  border: none !important;
}

#olark-wrapper .olark-end-chat-flag {
  fill: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-form-confirmation-icon .olark-launch-button-icon {
  fill: #2F6BC6 !important;
}

#olark-wrapper #olark-container .olark-form-message-input:focus {
  border-color: #2F6BC6 !important;
}

#olark-wrapper #olark-container path.olark-secondary {
  fill: #61D286 !important;
}

#olark-wrapper #olark-container:focus:not(.olark-inline) {
  border: 2px solid #61D286 !important;
}

#olark-wrapper .olark-button-focus-wrapper:focus-within {
  background-color: #61D286 !important;
}

#olark-wrapper #olark-container .olark-button {
  background-color: #61D286 !important;
  color: #FFFFFF !important;
}

#olark-wrapper #olark-container .olark-button:hover {
  background-color: #61D286 !important;
  color: #FFFFFF !important;
}

#olark-wrapper #olark-container .olark-form-send-button {
  background-color: #61D286 !important;
  color: #FFFFFF !important;
}

#olark-wrapper #olark-container .olark-restart-button {
  background-color: #61D286 !important;
  color: #FFFFFF !important;
}

#olark-wrapper #olark-container .olark-feedback-form-button {
  background-color: #61D286 !important;
  color: #FFFFFF !important;
}

#olark-wrapper #olark-container .olark-feedback-form-button:hover {
  background-color: #70d793 !important;
}

#olark-wrapper #olark-container .olark-footer-form-container .olark-chat-send-button:enabled svg path:not(:last-child) {
  fill: #61D286 !important;
  stroke: #61D286 !important;
}

#olark-wrapper #olark-container .olark-feedback-select-wrap:focus-within .olark-feedback-indicator svg path {
  stroke: #61D286 !important;
  stroke-width: 2px !important;
}

#olark-wrapper #olark-container .olark-options-message button {
  background-color: #61D286 !important;
  color: #FFFFFF !important;
  border: 2px solid #61D286 !important;
}

#olark-wrapper #olark-container .olark-agent-avatar {
  background-color: #61D286 !important;
}

.error-message{
  color: #fff;
  font-size: 15px;
  margin-bottom: 16px;
}

.bg-modal-success{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.bg-modal-success .modal-success{
  width: 90%;
  max-width: 700px;
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.bg-modal-success .modal-success h4{
  color: #61D286;
  font-weight: 500;
}

.bg-modal-success .modal-success p{
  margin-top: 20px;
  color: #555;
}

.bg-modal-success .modal-success .area-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
}

.bg-modal-success .modal-success .area-btn img{
  width: 180px;
}

.bg-modal-success .modal-success .btn-close{
  cursor: pointer;
  font-size: 14px;
  color: #777;
  margin-top: 20px;
  margin-bottom: -10px;
}

.bg-modal-success .modal-success .btn-close:hover{
  text-decoration: underline;
}