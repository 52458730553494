.page-hurst{
    background-color: #1C1F24;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.page-hurst .header{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.page-hurst .header .logo{
 
}

.page-hurst .container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #949494;
}

.page-hurst .container .area-text{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-family: sans-serif;
    position: relative;
    padding-bottom: 220px;
}

.page-hurst .container .area-text h1{
    font-weight: 100;
    font-size: 54px;
    text-align: center;
    line-height: 1.1;
}

.page-hurst .container .area-text b{
    font-weight: 700;
}

.page-hurst .container .area-text p{
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 100;
    margin-top: 10px;
    line-height: 1.7;
    text-align: center;
}

.page-hurst .container .area-text .gratis{
    width: 380px;
    height: 380px;
    position:absolute;
    bottom: -60px;
    left: -80px;
}

.page-hurst .container .area-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 380px;
    border: 1px solid white;
    border-radius: 4px;
    margin-left: 40px;
    padding: 40px;
}

.page-hurst .container .area-form input{
    width: 100%;
    height: 45px;
    border-radius: 10px;
    margin-top: 10px;
}

.page-hurst .container .area-form input::placeholder{
    font-size: 14px;
}

.page-hurst .container .area-form button{
    width: 100%;
    height: 70px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: #FFD55B;
    color: #191919;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.page-hurst .container .area-form button span{
    font-size: 12px;
    font-weight: 500;
}

/* Remova o estilo padrão do botão */
.custom-input-submit-hurst::-moz-focus-inner,
.custom-input-submit-hurst::-webkit-inner-spin-button,
.custom-input-submit-hurst::-webkit-outer-spin-button {
    border: none;
    padding: 0;
    margin: 0;
}

.page-hurst .footer{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    background-color: transparent;
    margin-top: 30px;
}

.page-hurst .footer .top-footer{
    display: flex;
    width: 100%;
    max-width: 1200px;
}

.page-hurst .footer .top-footer .logo-footer{
    margin-left: -150px;
    height: 42px;
}

.page-hurst .footer .content-footer{
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 60px;
}

.page-hurst .footer .content-footer .area-menu-social{
    display: flex;
    flex-direction: column;
}

.page-hurst .footer .content-footer .area-menu-social .title-menu{
    font-weight: bold;
    font-size: 14px;
}

.page-hurst .footer .content-footer .area-menu-social .menu-social{
    display: flex;
    flex-direction: column;
}

.page-hurst .footer .content-footer .area-menu-social .menu-social .item{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #d0d4d9;
    margin-top: 4px;
}

.page-hurst .footer .content-footer .area-menu-social .menu-social .item span{
    margin-left: 5px;
}

.page-hurst .footer .content-footer .area-menu-download{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.page-hurst .footer .content-footer .area-menu-download .title-menu{
    font-weight: bold;
    font-size: 14px;
}

.page-hurst .footer .content-footer .area-menu-download p{
    color: #d0d4d9;
    margin-top: 5px;
}

.page-hurst .footer .content-footer .area-menu-download .area-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.page-hurst .footer .content-footer .area-menu-download .area-btn .btn{
    width: 120px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.page-hurst .footer .content-footer .area-menu-download .area-btn .btn img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .page-hurst .container{
        flex-direction: column;
    }

    .page-hurst .container .area-form{
        margin-left: 0;
        margin-top: 20px;
        max-width: 1000px;
    }

    .page-hurst .container .area-text .gratis{
        width: 280px;
        height: 280px;
        left: -56px;
        bottom: 15px;
    }

    .page-hurst .footer .top-footer{
        display: flex;
        width: 100%;
        max-width: 1200px;
    }
    
    .page-hurst .footer .top-footer .logo-footer{
        margin-left: -80px;
    }

    .page-hurst .footer .content-footer{
        flex-direction: column;
        padding: 0 20px;
    }

    .page-hurst .footer .content-footer .area-menu-download{
        margin-left: 0;
        margin-top: 20px;
    }
}